<template>
  <div>
    <div>
      <button
        v-if="selectedLanguage === 'en'"
        @click="changeLanguage('ar')"
        class="bg-transparent text-primary fw-700"
      >
        العربية
        <img src="/assets/images/SA.svg" alt="">
      </button>
      <button
        v-if="selectedLanguage === 'ar'"
        class="bg-transparent text-primary fw-700"
        @click="changeLanguage('en')"
      >
        English
        <img src="/assets/images/US.svg" width="22px" height="12px" alt="">
      </button>
    </div>
    <div v-if="isLoading"></div> <!-- Loader -->
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';

const { locale, setLocaleMessage } = useI18n();
const languages = [
  { code: 'en', name: 'English' },
  { code: 'ar', name: 'العربية' },
];

const selectedLanguage = ref(localStorage.getItem('selectedLanguage') || 'ar');
const isLoading = ref(false); // Loading state

const setBodyDirection = (lang: string) => {
  const direction = lang === 'ar' ? 'rtl' : 'ltr';
  document.body.style.direction = direction;
  document.body.style.textAlign = lang === 'ar' ? 'right' : 'left';
};

const changeLanguage = async (lang: string) => {
  isLoading.value = true; // Show loading indicator

  try {
    // Use `pagination[limit]=-1` to fetch all records without pagination
    const response = await fetch(`https://cms.cartrust.dev/api/language-labels?locale=${lang}&pagination[pageSize]=2000`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const data = await response.json();
    if (!response.ok) throw new Error(`Server error: ${response.status}`);

    if (data && data.data) {
      // Transform the array of translations into an object
      const translations = data.data.reduce((acc: Record<string, string>, item: any) => {
        acc[item.label_key] = item.text;
        return acc;
      }, {});

      setLocaleMessage(lang, translations); // Set new translations
      locale.value = lang; // Update the current locale
      localStorage.setItem('selectedLanguage', lang); // Persist the selected language
      setBodyDirection(lang); // Update text direction
      selectedLanguage.value = lang; // Update the selected language
      console.log(`Language changed to: ${lang}`);
    } else {
      console.error(`No translations returned for language: ${lang}`);
    }
  } catch (error) {
    console.error('Error fetching language labels:', error);
  } finally {
    isLoading.value = false; // Hide loading indicator
  }
};



// Set the language and body direction when the component is mounted
onMounted(() => {
  setBodyDirection(selectedLanguage.value);
  changeLanguage(selectedLanguage.value);
});
</script>


<style scoped>
button {
  margin: 0 5px;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  background-color: #f0f0f0;
  border-radius: 5px;
}

button.active {
  background-color: #007bff; /* Active button color */
  color: white;
}

button:hover {
  background-color: #d3d3d3; /* Hover effect */
}
</style>
